<template>
  <div class="mini_program_body" v-loading="loading">
    <left-big-title
      >{{ title
      }} {{ $t("label.service.miniProgram.slideshowTitle") }}</left-big-title
    >
    <div class="operation">
      <div class="name">
        <div class="name_left" style="display: inline-block; margin-top: -18px">
          <span class="name_a">*</span>
          <span class="name_name"
            >{{ $t("label.service.miniProgram.slideshowName") }}:</span
          >
        </div>
        <div>
          <input v-model="carouselname" type="text" class="name_input" />
          <div class="introduce">
            {{ $t("label.service.miniProgram.slideshowintroduce") }}
          </div>
        </div>
      </div>
      <div class="name">
        <div class="name_left">
          <span class="name_a">*</span>
          <span class="name_name"
            >{{ $t("label.service.miniProgram.backgroundImage") }}:</span
          >
        </div>
        <div v-if="editImgSrc" class="maskFather">
          <div>
            <!-- 图片预览 -->
            <img
              v-on:mouseover="overImg()"
              v-on:mouseout="mouseOut()"
              :src="editImgSrc"
              class="avatar"
            />
            <!-- 遮罩层 -->
            <div class="mask" v-show="showMask">
              <div
                v-on:click="deletePic"
                class="deleteIcon el-icon-delete"
              ></div>
            </div>
          </div>
          <div class="introduce">
            {{ $t("label.service.miniProgram.imageType") }}
          </div>
        </div>
        <div v-if="!editImgSrc">
          <el-upload
            action=""
            :show-file-list="false"
            list-type="picture-card"
            :http-request="httpRequest"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus uploadAdd"></i>
          </el-upload>
          <div class="introduce">
            {{ $t("label.service.miniProgram.imageType") }}
          </div>
        </div>
      </div>

      <div class="name">
        <div class="name_left">
          <span class="name_a">*</span>
          <span class="name_name"
            >{{ $t("label.service.miniProgram.imageLink") }}:</span
          >
        </div>
        <input type="text" v-model="cUrl" class="name_input" />
      </div>
      <div class="name">
        <div class="name_left">
          <span class="name_name"
            >{{ $t("label.service.miniProgram.isOpen") }}:</span
          >
        </div>
        <el-radio v-model="radio" label="1">{{
          $t("label.tabpage.yesz")
        }}</el-radio>
        <el-radio v-model="radio" label="0">{{
          $t("label.tabpage.noz")
        }}</el-radio>
      </div>
      <div class="name">
        <div class="name_left">
          <span class="name_a">*</span>
          <span class="name_name">{{ $t("label.newprocess5.seq") }}:</span>
        </div>
        <input
          type="text"
          v-model="seq"
          class="name_input"
          :placeholder="$t('label.service.miniProgram.inputNumber')"
        />
      </div>
      <div class="empty">
        <button class="button" @click="empty">
          {{ $t("label.forecast.customforecast.button.submit") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import leftBigTitle from "./components/leftBigTitle";
import { savecarousel, getcarousel, updatecarousel } from "./api";
export default {
  name: "app",
  data() {
    return {
      loading: false,
      showMask: false,
      radio: "1",
      title: this.$i18n.t("label.add"),
      editImgSrc: "",
      
      dialogVisible: false,
      formData: null,
      carouselname: "",
      cUrl: "",
      seq: "",
      id: "",
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.$route.params.type == "add") {
      this.title = this.$i18n.t("label.add");
    } else if (this.$route.params.type == "edit") {
      this.title = this.$i18n.t("label.edit");
      this.getCarousel();
    }
    this.formData = new FormData();
  },
  methods: {
    // 不修改图片  更新轮播图
    async updateCarousel() {
      this.formData.append("carouselname", this.carouselname);
      this.formData.append("url", this.cUrl);
      this.formData.append("seq", Number(this.seq));
      this.formData.append("isactive", this.radio);
      this.formData.append("carouselid", this.id);
      let result = await updatecarousel(this.formData);
      if (result.result) {
        if (this.$route.params.type == "edit") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t(
              "label.setup.mobile.bottombutton.change.success"
            ),
          });
        } else if (this.$route.params.type == "add") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        }
        this.$router.go(-1);
      }
    },
    // 获取轮播图详情
    async getCarousel() {
      let obj = {
        carouselid: this.id,
      };
      let result = await getcarousel(obj);
      this.carouselname = result.data.carouselname;
      this.editImgSrc = result.data.previewurl;
      this.cUrl = result.data.url;
      this.label = result.data.isactive;
      this.seq = result.data.seq;
    },
    deletePic() {
      this.editImgSrc = "";
      this.showMask = false;
    },
    mouseOut() {
      this.showMask = false;
    },
    overImg() {
      this.showMask = true;
    },
    // 保存详情的接口
    async saveCarousel() {
      this.formData.append("carouselname", this.carouselname);
      this.formData.append("url", this.cUrl);
      this.formData.append("seq", Number(this.seq));
      this.formData.append("isactive", this.radio);
      if (this.type == "edit") {
        this.formData.append("carouselid", this.id);
      } else {
        this.formData.append("carouselid", "");
      }
      let result = await savecarousel(this.formData);
      this.loading = false;
      if (result.result) {
        if (this.$route.params.type == "edit") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t(
              "label.setup.mobile.bottombutton.change.success"
            ),
          });
        } else if (this.$route.params.type == "add") {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        }
        this.$router.go(-1);
      }
    },
    
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传图片
    httpRequest(data) {
      this.editImgSrc = URL.createObjectURL(data.file);
    },
    beforeAvatarUpload(file) {
      this.formData.append("file", file);
      this.formData.append("fileName", file.name);
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error(
          this.$i18n.t("label.service.miniProgram.imageFormat")
        );
      }
      if (!isLt2M) {
        this.$message.error(
          this.$i18n.t("label.service.miniProgram.imageFormat")
        );
      }
      return isJPG && isLt2M;
    },
    //提交
    empty() {
      if (this.carouselname == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.service.miniProgram.fillSlideshowName"),
        });
        return;
      }
      if (!this.editImgSrc) {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.service.miniProgram.uploadingImage"),
        });
        return;
      }
      if (this.cUrl == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.service.miniProgram.fillImageLink"),
        });
        return;
      }
      if (this.seq == "") {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("label.service.miniProgram.fillImageOrder"),
        });
        return;
      }
      let hasPic = this.formData.get("file");
      this.loading = true;
      if (hasPic) {
        this.saveCarousel();
      } else {
        this.updateCarousel();
      }
    },
  },
  components: {
    leftBigTitle,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
.deleteIcon {
  transform: scale(1.5);
}
.maskFather {
  position: relative;
}
.mask {
  width: 333px;
  height: 148px;
  background-color: rgba(116, 116, 116, 0.7);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ebebeb;
  position: absolute;
  left: 0;
  top: 0;
}
.name {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.name_a {
  color: #c12218;
}
.name_left {
  width: 120px;
  height: 19px;
  margin-left: 10px;
  text-align: right;
  margin-right: 20.2px;
}
.name_name {
  font-size: 14px;
  color: #151515;
  font-weight: 700;
}
.name_input {
  outline: none;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  border-radius: 3px;
  width: 325px;
  height: 30px;
  text-indent: 10px;
}
.introduce {
  font-size: 12px;
  color: #888888;
  margin-top: 4px;
}
.avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 245px;
  height: 170px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 333px;
  height: 148px;
  border-radius: 6px;
  display: block;
}
.name .el-radio__input.is-checked .el-radio__inner {
  border-color: #006dcc;
  background: #006dcc;
}
.name .el-radio__input.is-checked + .el-radio__label {
  color: #006dcc;
}
.empty {
  margin-top: 41px;
  margin-left: 20px;
  .button {
    background: #006dcc;
    width: 83px;
    height: 32px;
    font-size: 14px;
    color: #ffffff;
    outline: none;
    border: 1px solid #006dcc;
    border-radius: 3px;
  }
}
</style>
